import { Container, Row, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import img1 from "../musico.jpg"; // 🖼️ Reemplaza con tus imágenes reales
import img2 from "../steve.jpg"; // 🖼️ Reemplaza con tus imágenes reales

const ScreenIniti = () => {
  const navigate = useNavigate();

  return (
    <Container className="text-center mt-5">
      <h1 className="mb-4">Selecciona el concierto</h1>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} md={5} className="mb-4">
          <Image
            src={img1}
            alt="App 1"
            fluid
            rounded
            onClick={() => navigate("/1")}
            style={{ cursor: "pointer", boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
          />
          <h4 className="mt-3">Musiko</h4>
        </Col>
        <Col xs={12} md={5} className="mb-4">
          <Image
            src={img2}
            alt="App 2"
            fluid
            rounded
            onClick={() => navigate("/2")}
            style={{ cursor: "pointer", boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
          />
          <h4 className="mt-3">Steve</h4>
        </Col>
      </Row>
    </Container>
  );
};

export default ScreenIniti;