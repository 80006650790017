import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Table, Button, Spinner, Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const SeccionDetalle = ({ asientosGlobales, setAsientosGlobales }) => {
  const { seccion } = useParams();
  const navigate = useNavigate();
  const [asientos, setAsientos] = useState({});
  const [asientosComprados, setAsientosComprados] = useState([]);
  const [asientosTemporales, setAsientosTemporales] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [modalOcupado, setModalOcupado] = useState(false);

  const sessionId = (() => {
    let id = localStorage.getItem("session_id");
    if (!id) {
      id = uuidv4();
      localStorage.setItem("session_id", id);
    }
    return id;
  })();

  const cargarDatos = async () => {
    try {
      const [resAsientos, resComprados, resTemporales] = await Promise.all([
        fetch("/static/asientos.json"),
        fetch("/api/asientos-comprados"),
        fetch("/api/asientos-temporales"),
      ]);

      const datosAsientos = await resAsientos.json();
      const datosComprados = await resComprados.json();
      const datosTemporales = await resTemporales.json();

      setAsientos(datosAsientos[seccion] || {});
      setAsientosComprados(datosComprados.asientos || []);
      setAsientosTemporales(datosTemporales.asientos || []);
      setCargando(false);
    } catch (error) {
      console.error("Error al cargar datos:", error);
      setCargando(false);
    }
  };

  useEffect(() => {
    cargarDatos();
    const intervalo = setInterval(cargarDatos, 1000);
    return () => clearInterval(intervalo);
  }, [seccion]);

  const toggleAsiento = async (idAsiento) => {
    const yaSeleccionado = asientosGlobales.includes(idAsiento);

    if (yaSeleccionado) {
      setAsientosGlobales(asientosGlobales.filter((a) => a !== idAsiento));
      await fetch("/api/asiento-temporal", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ asiento: idAsiento, session_id: sessionId }),
      });
    } else {
      const res = await fetch("/api/asiento-temporal", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ asiento: idAsiento, session_id: sessionId }),
      });

      if (res.status === 409) {
        setModalOcupado(true);
        return;
      }

      setAsientosGlobales([...asientosGlobales, idAsiento]);
    }
  };

  if (cargando) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
        <p className="mt-2">Cargando asientos...</p>
      </Container>
    );
  }

  return (
    <Container>
      <div className="d-flex justify-content-start mt-3 mb-2">
        <Button variant="primary" onClick={() => navigate("/1")}>
          ← Regresar a inicio
        </Button>
      </div>

      <h2 className="text-center my-4">
        {seccion
          .replace("Seccion 1", "Planta baja 1")
          .replace("Seccion 2", "Planta baja 2")
          .replace("Seccion 3", "Planta baja 3")
          .replace("Seccion 4", "Planta baja 4")
          .replace("Seccion 5", "Planta alta 5")
          .replace("Seccion 6", "Planta alta 6")
          .replace("Seccion 7", "Planta alta 7")
          .replace("Seccion 8", "Planta alta 8")}
      </h2>
      {/* Mostrar asientos seleccionados */}
      {asientosGlobales.length > 0 && (
        <div className="my-3 p-3 border rounded bg-light">
          <h5>Asientos Seleccionados:</h5>
          <p>{asientosGlobales.map(asiento =>
            asiento.replace("Seccion 1", "Planta baja 1")
                   .replace("Seccion 2", "Planta baja 2")
                   .replace("Seccion 3", "Planta baja 3")
                   .replace("Seccion 4", "Planta baja 4")
                   .replace("Seccion 5", "Planta alta 5")
                   .replace("Seccion 6", "Planta alta 6")
                   .replace("Seccion 7", "Planta alta 7")
                   .replace("Seccion 8", "Planta alta 8")
          ).join(", ")}</p>
          <h5>Total a pagar: Q{asientosGlobales.length * 50}</h5>
          <Button variant="success" onClick={() => navigate("/1/finalizar-compra")}>Finalizar Compra</Button>
        </div>
      )}
      <Table bordered>
        <tbody>
          {Object.entries(asientos).map(([fila, numeros]) => (
            <tr key={fila}>
              <td><strong>{fila}</strong></td>
              <td>
                <div className="d-flex flex-wrap">
                  {numeros.map((num) => {
                    const idAsiento = `${seccion}-${fila}-${num}`;
                    const isComprado = asientosComprados.includes(idAsiento);
                    const isSeleccionado = asientosGlobales.includes(idAsiento);
                    const isTemporal = asientosTemporales
                      .filter((a) => a.session_id !== sessionId)
                      .some((a) => a.asiento === idAsiento);

                    let variant = "outline-primary";
                    if (isComprado) variant = "secondary";
                    else if (isSeleccionado) variant = "success";
                    else if (isTemporal) variant = "warning";

                    return (
                      <Button
                        key={idAsiento}
                        variant={variant}
                        className="m-1"
                        disabled={isComprado || (isTemporal && !isSeleccionado)}
                        onClick={() => toggleAsiento(idAsiento)}
                      >
                        {num}
                      </Button>
                    );
                  })}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal de asiento ocupado */}
      <Modal
        show={modalOcupado}
        onHide={() => setModalOcupado(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Asiento Ocupado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          El asiento que intentaste seleccionar ya fue reservado por otro usuario.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setModalOcupado(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SeccionDetalle;