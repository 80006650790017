import { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

const nombreInterno = {
  "Planta baja 4": "Seccion 4",
  "Planta baja 3": "Seccion 3",
  "Planta baja 2": "Seccion 2",
  "Planta baja 1": "Seccion 1",
  "Planta alta 8": "Seccion 8",
  "Planta alta 7": "Seccion 7",
  "Planta alta 6": "Seccion 6",
  "Planta alta 5": "Seccion 5",
};

const secciones = Object.keys(nombreInterno);

const VistaGeneral = () => {
  const [asientosData, setAsientosData] = useState({});
  const [asientosComprados, setAsientosComprados] = useState([]);
  const [asientosTemporales, setAsientosTemporales] = useState([]);

  useEffect(() => {
    const cargarAsientos = async () => {
      try {
        const response = await fetch("/static/asientos.json");
        const data = await response.json();
        setAsientosData(data);
      } catch (error) {
        console.error("Error al cargar asientos.json", error);
      }
    };

    const cargarAsientosComprados = async () => {
      try {
        const response = await fetch("/api/asientos-comprados");
        const data = await response.json();
        setAsientosComprados(data.asientos || []);
      } catch (error) {
        console.error("Error al cargar asientos comprados", error);
      }
    };

    const cargarAsientosTemporales = async () => {
      try {
        const response = await fetch("/api/asientos-temporales");
        const data = await response.json();
        const temporales = data.asientos?.map((a) => a.asiento) || [];
        setAsientosTemporales(temporales);
      } catch (error) {
        console.error("Error al cargar asientos temporales", error);
      }
    };

    cargarAsientos(); // solo una vez
    cargarAsientosComprados();
    cargarAsientosTemporales();

    const intervalo = setInterval(() => {
      cargarAsientosComprados();
      cargarAsientosTemporales();
    }, 1000);

    return () => clearInterval(intervalo);
  }, []);

  const renderSeccion = (seccion) => {
    const nombreDB = nombreInterno[seccion];
    const asientos = asientosData[nombreDB] || {};

    return (
      <Card className="w-100 h-100">
        <Card.Header className="text-center fw-bold p-1">{seccion}</Card.Header>
        <Card.Body className="p-1 d-flex flex-column justify-content-center align-items-center">
          <div style={{ fontSize: "0.55rem", width: "100%" }}>
            {Object.entries(asientos).map(([fila, numeros]) => (
              <div key={fila} className="d-flex align-items-center justify-content-start mb-1" style={{ flexWrap: "wrap" }}>
                <div style={{ width: "1rem", textAlign: "center" }}>{fila}</div>
                <div className="d-flex flex-wrap ms-1" style={{ gap: "2px" }}>
                  {numeros.map((num) => {
                    const id = `${nombreDB}-${fila}-${num}`;
                    const isComprado = asientosComprados.includes(id);
                    const isTemporal = asientosTemporales.includes(id);

                    let variant = "outline-primary";
                    if (isComprado) variant = "secondary";
                    else if (isTemporal) variant = "warning";

                    return (
                      <Button
                        key={id}
                        size="sm"
                        variant={variant}
                        className="px-1 py-0"
                        disabled
                        style={{
                          fontSize: "0.5rem",
                          minWidth: "18px",
                          height: "20px",
                          opacity: isTemporal ? 0.85 : 1,
                        }}
                      >
                        {num}
                      </Button>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Container
      fluid
      className="p-0"
      style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
    >
      <Row className="gx-0 gy-0" style={{ height: "100%" }}>
        {secciones.map((seccion) => (
          <Col
            key={seccion}
            xs={6}
            md={3}
            className="p-1"
            style={{
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {renderSeccion(seccion)}
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default VistaGeneral;