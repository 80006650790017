import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card, Image, Modal, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import diagram from "../diagram.png";
import musico from "../musico.jpg";

const Home = ({ asientosGlobales }) => {
  const niveles = {
    "Nivel 1": ["Planta baja 4", "Planta baja 3", "Planta baja 2", "Planta baja 1"],
    "Nivel 2": ["Planta alta 8", "Planta alta 7", "Planta alta 6", "Planta alta 5"],
  };

  const navigate = useNavigate();
  const [mostrarModal, setMostrarModal] = useState(false);
  const [asientosTemporales, setAsientosTemporales] = useState([]);
  const [cargando, setCargando] = useState(false);

  const cargarTemporales = async () => {
    setCargando(true);
    try {
      const res = await fetch("/api/asientos-temporales");
      const data = await res.json();
      setAsientosTemporales(data.asientos || []);
    } catch (err) {
      console.error("Error al cargar asientos temporales:", err);
    } finally {
      setCargando(false);
    }
  };

  const limpiarTemporales = async () => {
    try {
      const res = await fetch("/api/asientos-temporales", {
        method: "DELETE",
      });
      const data = await res.json();
      alert(data.message || "Asientos temporales eliminados.");
      setMostrarModal(false);
    } catch (err) {
      console.error("Error al limpiar asientos temporales:", err);
    }
  };

  return (
    <Container style={{ position: "relative", zIndex: 1 }}>
      {/* 🎵 Imagen decorativa en esquina superior derecha */}
      <div
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          opacity: 0.2,
          zIndex: 0,
        }}
      >
        <img src={musico} alt="Músico" style={{ width: "400px", height: "auto" }} />
      </div>

      {/* 🔹 Botones superiores */}
      <div className="d-flex justify-content-start mt-3 mb-2 gap-2">
        <Link to="/">
          <Button variant="secondary">← Inicio</Button>
        </Link>
        <Link to="/1/compras">
          <Button variant="primary">Ver Entradas Compradas</Button>
        </Link>
        <Button variant="warning" onClick={() => { setMostrarModal(true); cargarTemporales(); }}>
          Ver Asientos Temporales
        </Button>
      </div>

      <h1 className="text-center mb-4">Selección de Asientos</h1>

      {/* Imagen del diagrama del auditorio */}
      <div className="text-center mb-4">
        <Image src={diagram} alt="Diagrama de Secciones" fluid style={{ width: "30%" }} />
      </div>

      {/* Mostrar asientos seleccionados */}
      {asientosGlobales.length > 0 && (
        <div className="my-3 p-3 border rounded bg-light">
          <h5>Asientos Seleccionados:</h5>
          <p>{asientosGlobales.map(asiento =>
            asiento.replace("Seccion 1", "Planta baja 1")
                   .replace("Seccion 2", "Planta baja 2")
                   .replace("Seccion 3", "Planta baja 3")
                   .replace("Seccion 4", "Planta baja 4")
                   .replace("Seccion 5", "Planta alta 5")
                   .replace("Seccion 6", "Planta alta 6")
                   .replace("Seccion 7", "Planta alta 7")
                   .replace("Seccion 8", "Planta alta 8")
          ).join(", ")}</p>
          <h5>Total a pagar: Q{asientosGlobales.length * 50}</h5>
          <Button variant="success" onClick={() => navigate("/1/finalizar-compra")}>Finalizar Compra</Button>
        </div>
      )}

      {/* Secciones por nivel */}
      {Object.entries(niveles).map(([nivel, secciones]) => (
        <div key={nivel} className="mb-4">
          <h3>{nivel}</h3>
          <Row>
            {secciones.map((seccion) => (
              <Col key={seccion} md={3} className="mb-3">
                <Card>
                  <Card.Body className="text-center">
                    <Card.Title>{seccion}</Card.Title>
                    <Link to={`/1/seccion/${seccion.replace("Planta baja ", "Seccion ").replace("Planta alta ", "Seccion ")}`}>
                      <Button variant="primary">Ver asientos</Button>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ))}

      {/* Modal de asientos temporales */}
      <Modal show={mostrarModal} onHide={() => setMostrarModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Asientos Seleccionados Temporalmente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cargando ? (
            <div className="text-center">
              <Spinner animation="border" />
              <p className="mt-2">Cargando...</p>
            </div>
          ) : asientosTemporales.length === 0 ? (
            <p>No hay asientos temporales en este momento.</p>
          ) : (
            <ul>
              {asientosTemporales.map((a, idx) => (
                <li key={idx}>{a.asiento} (session: {a.session_id})</li>
              ))}
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setMostrarModal(false)}>Cerrar</Button>
          <Button variant="danger" onClick={limpiarTemporales}>Limpiar Todos</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Home;