import { useEffect, useState } from "react";
import { Container, Table, Spinner, Alert, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const Compras2 = () => {
  const [compras, setCompras] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const cargarCompras = () => {
    fetch("/api/compras2")
      .then(res => res.json())
      .then(data => {
        if (Array.isArray(data)) {
          setCompras(data);
        } else {
          setError("Error al cargar los datos");
        }
        setLoading(false);
      })
      .catch(err => {
        setError("Error de conexión con el servidor");
        setLoading(false);
      });
  };

  useEffect(() => {
    cargarCompras();
  }, []);

  const eliminarCompra = async (id) => {
    const confirmar = window.confirm("¿Estás seguro de que deseas eliminar esta compra?");
    if (!confirmar) return;

    try {
      const res = await fetch(`/api/compra2/${id}`, {
        method: "DELETE",
      });

      if (res.ok) {
        alert("Compra eliminada con éxito.");
        cargarCompras();
      } else {
        alert("Error al eliminar la compra.");
      }
    } catch (error) {
      console.error("Error al eliminar:", error);
      alert("Ocurrió un error en el servidor.");
    }
  };

  const normalizarAsientos = (texto) => {
    return texto
      .replace(/Seccion 1/g, "Planta baja 1")
      .replace(/Seccion 2/g, "Planta baja 2")
      .replace(/Seccion 3/g, "Planta baja 3")
      .replace(/Seccion 4/g, "Planta baja 4")
      .replace(/Seccion 5/g, "Planta alta 5")
      .replace(/Seccion 6/g, "Planta alta 6")
      .replace(/Seccion 7/g, "Planta alta 7")
      .replace(/Seccion 8/g, "Planta alta 8");
  };

  const comprasFiltradas = compras.map((compra) => ({
    ...compra,
    asientos: normalizarAsientos(compra.asientos),
  })).filter((compra) =>
    Object.values(compra).some((valor) =>
      String(valor).toLowerCase().includes(busqueda.toLowerCase())
    )
  );

  const totalAsientos = comprasFiltradas.reduce((total, compra) => {
    return total + compra.asientos.split(",").filter(Boolean).length;
  }, 0);

  const exportarExcel = () => {
    const hoja = comprasFiltradas.map(c => ({
      ID: c.id,
      Nombre: c.nombre_completo,
      NIT: c.nit,
      Correo: c.correo,
      Total: c.total,
      Orden: c.numero_orden,
      Asientos: c.asientos,
      Cantidad: c.asientos.split(",").filter(Boolean).length,
      Fecha: new Date(c.fecha).toLocaleString(),
    }));
    const ws = XLSX.utils.json_to_sheet(hoja);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Compras2");
    const fecha = new Date().toISOString().slice(0, 10);
    XLSX.writeFile(wb, `compras2_${fecha}.xlsx`);
  };

  const exportarPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text("Entradas Compradas (Versión 2)", 14, 20);
    autoTable(doc, {
      startY: 30,
      head: [[
        "ID", "Nombre", "NIT", "Correo", "Total (Q)", "Orden", "Cantidad", "Asientos", "Fecha"
      ]],
      body: comprasFiltradas.map(c => [
        c.id,
        c.nombre_completo,
        c.nit,
        c.correo,
        c.total,
        c.numero_orden,
        c.asientos.split(",").filter(Boolean).length,
        c.asientos,
        new Date(c.fecha).toLocaleString()
      ]),
      styles: { fontSize: 7 },
      headStyles: { fillColor: [52, 58, 64] },
    });
    const fecha = new Date().toISOString().slice(0, 10);
    doc.save(`compras2_${fecha}.pdf`);
  };

  return (
    <Container className="my-4">
      <div className="d-flex justify-content-between mb-3">
        <Link to="/2">
          <Button variant="secondary">← Volver al Inicio</Button>
        </Link>
        <div className="d-flex gap-2">
          <Button variant="success" onClick={exportarExcel}>Exportar a Excel</Button>
          <Button variant="danger" onClick={exportarPDF}>Exportar a PDF</Button>
        </div>
      </div>

      <h2 className="text-center mb-3">Entradas Compradas (Versión 2)</h2>

      {loading && <Spinner animation="border" />}
      {error && <Alert variant="danger">{error}</Alert>}

      {!loading && !error && (
        <>
          <Form className="mb-3">
            <Form.Control
              type="text"
              placeholder="Buscar en la tabla..."
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
            />
          </Form>

          <p><strong>Total de Asientos Comprados:</strong> {totalAsientos}</p>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>NIT</th>
                <th>Correo</th>
                <th>Total (Q)</th>
                <th>Orden</th>
                <th>Cantidad</th>
                <th>Asientos</th>
                <th>Fecha</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {comprasFiltradas.map((c) => (
                <tr key={c.id}>
                  <td>{c.id}</td>
                  <td>{c.nombre_completo}</td>
                  <td>{c.nit}</td>
                  <td>{c.correo}</td>
                  <td>{c.total}</td>
                  <td>{c.numero_orden}</td>
                  <td>{c.asientos.split(",").filter(Boolean).length}</td>
                  <td>{c.asientos}</td>
                  <td>{new Date(c.fecha).toLocaleString()}</td>
                  <td>
                    <Button variant="danger" size="sm" onClick={() => eliminarCompra(c.id)}>
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  );
};

export default Compras2;