import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";

// Página de inicio
import ScreenIniti from "./pages/ScreenIniti";

// App original
import Home from "./pages/Home";
import SeccionDetalle from "./pages/SeccionDetalle";
import FinalizarCompra from "./pages/FinalizarCompra";
import Compras from "./pages/Compras";
import VistaGeneral from "./pages/VistaGeneral";

// App duplicada
import Home2 from "./pages/Home2";
import SeccionDetalle2 from "./pages/SeccionDetalle2";
import FinalizarCompra2 from "./pages/FinalizarCompra2";
import Compras2 from "./pages/Compras2";
import VistaGeneral2 from "./pages/VistaGeneral2";

const App = () => {
  const [asientosGlobales, setAsientosGlobales] = useState([]);
  const [asientosGlobales2, setAsientosGlobales2] = useState([]); // para app duplicada

  return (
    <Container>
      <Routes>
        {/* Página de selección de apps */}
        <Route path="/" element={<ScreenIniti />} />

        {/* App original (en /1) */}
        <Route path="/1" element={<Home asientosGlobales={asientosGlobales} />} />
        <Route
          path="/1/seccion/:seccion"
          element={
            <SeccionDetalle
              asientosGlobales={asientosGlobales}
              setAsientosGlobales={setAsientosGlobales}
            />
          }
        />
        <Route
          path="/1/finalizar-compra"
          element={
            <FinalizarCompra
              asientosGlobales={asientosGlobales}
              setAsientosGlobales={setAsientosGlobales}
            />
          }
        />
        <Route path="/1/compras" element={<Compras />} />
        <Route path="/1/vista-general" element={<VistaGeneral />} />

        {/* App duplicada (en /2) */}
        <Route path="/2" element={<Home2 asientosGlobales={asientosGlobales2} />} />
        <Route
          path="/2/seccion/:seccion"
          element={
            <SeccionDetalle2
              asientosGlobales={asientosGlobales2}
              setAsientosGlobales={setAsientosGlobales2}
            />
          }
        />
        <Route
          path="/2/finalizar-compra"
          element={
            <FinalizarCompra2
              asientosGlobales2={asientosGlobales2}
              setAsientosGlobales2={setAsientosGlobales2}
            />
          }
        />
        <Route path="/2/compras" element={<Compras2 />} />
        <Route path="/2/vista-general" element={<VistaGeneral2 />} />
      </Routes>
    </Container>
  );
};

export default App;