// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Proteccion from "./components/Proteccion";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, useLocation } from "react-router-dom";

const RutasProtegidas = () => {
  const location = useLocation();

  const esRutaApi = location.pathname.startsWith("/api");
  const esAsset = location.pathname.startsWith("/static");

  if (esRutaApi || esAsset) {
    return <App />; // No aplicar protección
  }

  return (
    <Proteccion>
      <App />
    </Proteccion>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <RutasProtegidas />
  </BrowserRouter>
);