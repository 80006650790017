import { useState, useEffect } from "react";
import { Container, Form, Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const FinalizarCompra2 = ({ asientosGlobales2 = [], setAsientosGlobales2 }) => {
  const navigate = useNavigate();
  const [asientos, setAsientos] = useState(asientosGlobales2 || []);
  const totalPago = asientos.length * 50;

  const [nombre, setNombre] = useState("");
  const [nit, setNIT] = useState("");
  const [correo, setCorreo] = useState("");
  const [mostrarModal, setMostrarModal] = useState(false);
  const [procesando, setProcesando] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [numeroOrden, setNumeroOrden] = useState("");

  useEffect(() => {
    // Leer los asientos desde localStorage si el estado está vacío
    if (asientos.length === 0) {
      const asientosGuardados = JSON.parse(localStorage.getItem("asientos2") || "[]");
      setAsientos(asientosGuardados);
    }
  }, []);

  useEffect(() => {
    let id = localStorage.getItem("session_id_2");
    if (!id) {
      id = uuidv4();
      localStorage.setItem("session_id_2", id);
    }
    setSessionId(id);
  }, []);

  useEffect(() => {
    // Cleanup automático si el usuario abandona la página sin confirmar
    return () => {
      if (asientos.length > 0) {
        asientos.forEach((asiento) => {
          fetch("/api/asiento-temporal2", {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ asiento, session_id: sessionId }),
          });
        });
        setAsientosGlobales2?.([]);
        localStorage.removeItem("asientos2");
      }
    };
  }, [asientos, sessionId, setAsientosGlobales2]);

  const generarNumeroOrden = (nombre, nit, correo) => {
    const base = `${nombre}-${nit}-${correo}-${Date.now()}`;
    let hash = 0;
    for (let i = 0; i < base.length; i++) {
      hash = (hash << 5) - hash + base.charCodeAt(i);
      hash |= 0;
    }
    return String(Math.abs(hash)).padStart(8, "0").slice(0, 8);
  };

  const limpiarAsientosTemporales = async () => {
    for (const asiento of asientos) {
      await fetch("/api/asiento-temporal2", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ asiento, session_id: sessionId }),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcesando(true);

    const nuevoNumeroOrden = generarNumeroOrden(nombre, nit, correo);
    setNumeroOrden(nuevoNumeroOrden);

    try {
      const res = await fetch("/api/compra2", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          nombre,
          nit,
          correo,
          total: totalPago,
          numero_orden: nuevoNumeroOrden,
          asientos,
        }),
      });

      setProcesando(false);

      if (res.ok) {
        await limpiarAsientosTemporales();
        setMostrarModal(true);
        setAsientos([]);
        setAsientosGlobales2?.([]);
        localStorage.removeItem("asientos2");
      } else {
        const data = await res.json();
        if (data?.asientos_duplicados) {
          alert(`❌ Algunos asientos ya fueron comprados:\n${data.asientos_duplicados.join(", ")}`);
        } else {
          alert("Error al procesar la compra.");
        }
        await limpiarAsientosTemporales();
        setAsientos([]);
        setAsientosGlobales2?.([]);
        localStorage.removeItem("asientos2");
        navigate("/2");
      }
    } catch (error) {
      console.error("Error:", error);
      setProcesando(false);
      await limpiarAsientosTemporales();
      alert("Error del servidor.");
    }
  };

  const volverInicio = () => {
    setMostrarModal(false);
    navigate("/2");
  };

  return (
    <Container>
      <h2 className="text-center my-4">Finalizar Compra</h2>
      <Form
        onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <Form.Group className="mb-3">
          <Form.Label>Nombre Completo</Form.Label>
          <Form.Control
            type="text"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>NIT</Form.Label>
          <Form.Control
            type="text"
            value={nit}
            onChange={(e) => setNIT(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Correo Electrónico</Form.Label>
          <Form.Control
            type="email"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Total a Pagar</Form.Label>
          <Form.Control type="text" value={`Q${totalPago}`} readOnly />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Número de Orden</Form.Label>
          <Form.Control
            type="text"
            value={numeroOrden || "Se generará al confirmar"}
            readOnly
          />
        </Form.Group>

        <Button variant="success" type="submit" disabled={procesando}>
          Confirmar Compra
        </Button>
        <Button variant="secondary" className="ms-2" onClick={volverInicio} disabled={procesando}>
          ← Volver al Inicio
        </Button>
      </Form>

      <Modal show={procesando} centered backdrop="static" keyboard={false}>
        <Modal.Body className="text-center">
          <Spinner animation="border" className="mb-3" />
          <div>Procesando compra...</div>
        </Modal.Body>
      </Modal>

      <Modal show={mostrarModal} onHide={volverInicio} centered>
        <Modal.Header closeButton>
          <Modal.Title>Compra Exitosa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¡Gracias por tu compra! Tu número de orden es: <strong>{numeroOrden}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={volverInicio}>
            Volver al Inicio
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default FinalizarCompra2;