import { useState, useEffect } from "react";

const CONTRASENA = "N@z@+2025";

const Proteccion = ({ children }) => {
  const [autenticado, setAutenticado] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    const accesoGuardado = localStorage.getItem("acceso-autorizado");
    if (accesoGuardado === "true") {
      setAutenticado(true);
    }
  }, []);

  const manejarAcceso = () => {
    if (password === CONTRASENA) {
      setAutenticado(true);
      localStorage.setItem("acceso-autorizado", "true");
    } else {
      alert("Contraseña incorrecta.");
    }
  };

  // Evita proteger rutas de API o assets
  const pathname = window.location.pathname;
  const estaEnRutaApi = pathname.startsWith("/api") || pathname.startsWith("/static");

  if (autenticado || estaEnRutaApi) {
    return children;
  }

  return (
    <div style={{ height: "100vh" }} className="d-flex flex-column justify-content-center align-items-center bg-dark text-white">
      <h2 className="mb-4">Acceso Restringido</h2>
      <input
        type="password"
        className="form-control w-50 text-center"
        placeholder="Ingrese la contraseña"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={manejarAcceso} className="btn btn-light mt-3">
        Ingresar
      </button>
    </div>
  );
};

export default Proteccion;